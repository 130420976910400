import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import * as styles from './account.module.css';
import Layout from '../components/layout';
import { NotFoundContent } from '../components/not_found';
import { loadAccountData } from '../components/account/load';
import { AccountDescription } from '../components/account/description';
import { PaymentMethod } from '../components/account/payment';
import { AccountDetails } from '../components/account/details';
import { AccountRenew } from '../components/account/manage';
import { ChangePassword } from '../components/account/password';
import { subscriptionTypeDerived } from '../components/account/state';
import { hr } from "../components/brief/lines";
import LogOut from '../components/logout';
import { colors } from '../components/colors';
import { Message, messageByFieldError } from '../components/message';
import { empty } from '../utils/functional';
import { refreshSessionId } from '../utils/session_id';
import { inputDisabledState, messageState, remoteDatomState, subscriptionTypeState } from '../utils/state';
import { RenderHead } from "../components/head";



//
// configuration

const originType = process.env.ORIGIN_TYPE;
const memberContent = originType === 'private' || originType === 'expired' || originType === 'shifts-email-only';
const globalMessageKey = 'descriptionGlobal';

const isIE = () => /*@cc_on!@*/false || !!document.documentMode;
// from: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser

export const Head = () => RenderHead({ title: memberContent ? 'Account' : 'Not Found' });


//
// rendering helpers

const PrivateLoadedElements = () => {
    
    const userData = useRecoilValue(remoteDatomState('user'));
    if ( empty(userData) ) return ( <div/> );
    
    return (
        <div style={{
            margin: 0,
            padding: 0,
            width: `100%`
        }} >
          <div
            style={{
                margin: `32px 0 0`,
                width: `100%`
            }} >
            { hr() }
          </div>

          <PaymentMethod />

          <div
            style={{
                margin: `32px 0 0`,
                width: `100%`
            }} >
            { hr() }
          </div>

          <AccountDetails />
          <ChangePassword />
          
        </div>
    );
};

const PrivateContentElements = ({ title, userFields }) => {
    return (
        <div
          className={ styles.container } 
          style={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `flex-start`
          }} >

          <div
            style={{
                margin: 0,
                width: `100%`,
                fontFamily: `"Overpass", sans-serif`,
                fontSize: `40px`,
                fontWeight: `bold`,
                lineHeight: `1.13`,
                color: colors.green
            }} >
            { title }
          </div>


          <AccountDescription />

            { AccountRenew({ globalMessageK: globalMessageKey, userFields }) }
          
          <Message
            name={ 'GlobalMessage' }
            stateKey={ globalMessageKey }
            style={{
                margin: `10px 0 0`,
                width: `100%`,
                minHeight: `25px`,
                fontSize: `14px`
            }} />

          <PrivateLoadedElements />
          
          <div
            style={{
                margin: `32px 0 0`,
                width: `100%`
            }} >
            { hr() }
          </div>

          <div
            style={{
                margin: `60px 0 0`,
                width: `100%`
            }} >
            
            <LogOut
              style={{
                  textDecoration: `underline`
              }} />
            
          </div>
          
        </div>
    );
};



//
// component

const remoteDataLoadedState = atom({
    key: 'remoteDataLoadedState',
    default: false
});


const Account = ({ data, location }) => {
    
    const setGlobalMessage                             = useSetRecoilState( messageState(globalMessageKey) );
    const [inFlight, setInFlight]                      = useRecoilState(    inputDisabledState );
    const setRemoteData                                = useSetRecoilState( remoteDatomState('user') );
    const [hasLoaded, setLoaded]                       = useRecoilState(    remoteDataLoadedState );
    const subscriptionTypeFromData                     = useRecoilValue(    subscriptionTypeDerived );
    const [subscriptionType, setSubscriptionTypeState] = useRecoilState(    subscriptionTypeState );

    
    // load states
    const shouldTryLoading = memberContent && typeof window !== 'undefined';
    useEffect(() => {
        if ( isIE() ) setGlobalMessage({ error: messageByFieldError.global.browser });
        else if ( shouldTryLoading && ! hasLoaded && ! inFlight )
        {
            setInFlight(true);
            loadAccountData()
                .then((d) => {
                    if ( d.message ) 
                        setGlobalMessage({ error: d.message });

                    setRemoteData(d);
                    setLoaded(true);
                    setInFlight(false);
                    return;
                })
                .catch((e) => {
                    setGlobalMessage({ error: messageByFieldError.global.connectivity });
                    console.log('account page - loadAccountData catch');
                    console.log(e);
                    console.log(e.stack);
                });
        }
    });  // no dependencies since we only want this to run once, on load

    useEffect(() => {
        if ( subscriptionTypeFromData
             && subscriptionType !== subscriptionTypeFromData )
            setSubscriptionTypeState( subscriptionTypeFromData );
    }, [setSubscriptionTypeState, subscriptionType, subscriptionTypeFromData]);
    
    useEffect(() => { refreshSessionId(); }, []);


    // catch public (not signed in) requests
    if ( ! memberContent ) {
        return (
            <Layout
                location={ location }
                removeHorizontalPadding={ true } >
                <NotFoundContent />
            </Layout>
        );
    }
    
    
    const title = 'Your Account';
    const userFields = data.allUserFieldsJson.edges[0].node;

    return (
        <Layout
            location={ location }
            removeHorizontalPadding={ true } >
            { PrivateContentElements({ title, userFields }) }
        </Layout>
    );
};

export default Account;



export const pageQuery = graphql`
  query {
    allInternalBriefsJson {
      edges {
        node {
          body
          body_callouts
          body_partial
          body_preview
          body_quotes
          brief_id
          created_epoch_ts
          disclosure
          headline
          headline_pre
          podcast { id type }
          published_date_str
          published_epoch_ts
          published_date_word_str
          shift_titles
          slug_path
          social
          summary
          tags
          type
          word_count
        }
      }
    }

    allUserFieldsJson {
      edges {
        node {
          market_events
          market_sectors
          signals_selected
          shifts_researched
          quick_hits
        }
      }
    }
  }
`;

import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
    assoc, empty, getIn,
    mapVals, merge, reduceKv,
    reduceKvAsync, removeKv,
    renameKeys, someKv,
    threadLast } from '../../utils/functional';
import { httpPromise } from '../../utils/http/async';
import { graphqlRequest } from '../../utils/http/graphql';
import { fieldsState, formState, messagesState, remoteDatomState } from '../../utils/state';

import { Input } from '../join/Input';
import { messageByFieldError, Message } from '../message';
import { validateEmail } from '../join/validate';
import { ActionButtons } from './shared';
import * as styles from './shared.module.css';


const fieldKs = ['email', 'nameFirst', 'nameLast'];
const globalMessageK = 'detailsGlobal';
const inputDisabledStateK = 'inFlightAccountDetails';
const messageKs = fieldKs.concat([globalMessageK]);


const fieldToServerKs = {
    email:         'email',
    nameFirst:     'first_name',
    nameLast:      'last_name',
    password:      'password'
};



//
// validations

const errorTypeByField = {
    nameFirst:   async (v) => empty(v) ? 'empty' : null,
    nameLast:    async (v) => empty(v) ? 'empty' : null,
    email:       async (v) => await validateEmail(v)
};

const getErrorTypeByField = async (fields) => {
    return await reduceKvAsync(
        async (acc, k, v) => {
            const e = await errorTypeByField[k](fields[k]);
            return ! e ? acc : assoc(acc, k, e);
        }, {}, fields);
};

const hasFieldChanged = ({ fieldName, fields, userData }) =>  userData[fieldToServerKs[fieldName]] !== fields[fieldName];

const isSameEmailError = ({ fields, userData }) => {  
    return ( fieldName, errorName ) => {
        return fieldName === 'email' && errorName === 'exists'
            && ! hasFieldChanged({ fieldName, fields, userData });
    };
};



//
// mutation -> server

const dbCompatibleKeyMap = {
    nameFirst: 'first_name',
    nameLast:  'last_name',
    email:     'email'
};

const userMutationRequest = (data) => {
    const dbData = renameKeys(data, dbCompatibleKeyMap);
    return graphqlRequest([{
        name: 'user',
        query: dbData,
        projection: Object.keys(dbData)
    }], 'mutation');
};

const servicePath = process.env.USER_API_URL;

const toService = async (request) => {
    const rd = await httpPromise({ url: servicePath, data: request });
    const r = rd.response;
    if ( ! r ) return null;
    try {
        return JSON.parse(r);
    } catch (e) {
        return null;
    }
};



//
// validate then send

const validateThenSend = async ({ fields, userData }) => {
    const etbyf = await getErrorTypeByField(fields);
    const rf = isSameEmailError({ fields, userData });
    const errorTypeByField = removeKv(rf, etbyf);
    if ( ! empty(errorTypeByField) ) return { errorTypeByField };

    // passed validations
    const request = userMutationRequest(fields);
    const response = await toService(request);
    if ( response === null
         || ! response.data
         || ! response.data.user
         || empty(response.data.user) )
        return { errorTypeByField: { [globalMessageK]: 'connectivity' } };
    return response;  // success!
};


//
// component

const GlobalMessage = () => {
    //if ( ! content ) return ( <div/> );
    return (
        <Message
          stateKey={ globalMessageK }
          name='MessageDetailsGlobal'
          style={{
              margin: `5px 0 0`,
              width: `100%`,
              fontSize: `13px`,
              fontStyle: `italic`,
              letterSpacing: `0.13px`
          }} />
    );
};


export const AccountDetails = () => {
    const [fields, setFields]               = useRecoilState(fieldsState(fieldKs));
    const [inputDisabled, setInFlight]      = useRecoilState(formState(inputDisabledStateK));
    const [userData, setUserData]           = useRecoilState(remoteDatomState('user'));
    const [messages, setMessages]           = useRecoilState(messagesState(messageKs));

    const fieldsChanged = ! userData ? false :
          someKv((k,v) => {
              const udv = userData[fieldToServerKs[k]];
              if ( empty(v) && empty(udv) ) return false;
              return v !== udv;
          }, fields);

    const resetFields = () => {
        const nd = fieldKs
              .map((k) => fieldToServerKs[k])
              .map((k) => userData && userData[k] ? userData[k] : '');
        return setFields(nd);
    };
    useEffect(resetFields, [setFields, userData]);

    const clearMessages = () => setMessages(messageKs.map((_) => { return {}; }));

    const cancel = () => {
        resetFields();
        clearMessages();
        return;
    };

    const submit = (event) => {
        event.preventDefault();
        if ( inputDisabled ) return;
        setInFlight(true);
        clearMessages();
        const changedFields = removeKv( (k,v) => v === userData[fieldToServerKs[k]], fields);
        validateThenSend({ fields: changedFields, userData: userData })
            .then( (result) => {
                const { data, errorTypeByField } = result;
                if ( empty(errorTypeByField) && ! empty(data) )
                {  // success!
                    setUserData( merge(userData, data.user) );
                    return;
                }

                // errors
                const mdbyf = threadLast( errorTypeByField,
                                          [reduceKv, (a,k,v) => assoc(a,k, getIn(messageByFieldError, [k,v])), {}],
                                          [mapVals, (v) => assoc({},'error',v)],
                                          [reduceKv, (a,k,v) => assoc(a,k, merge(messages[k], v)), {}] );
                const orderedMessageData = messageKs.map((k) => mdbyf[k]);
                return setMessages(orderedMessageData);
            })
            .finally(() => setInFlight(false));
    };
    
    return (
        <div
          style={{
              margin: `60px 0 0`,
              width: `100%`,
              padding: `0`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `flex-start`
          }} >

          <div
            className={ styles.sectionTitle }
            style={{
                margin: 0,
                width: `100%`
            }} >
            Account Details
          </div>

          <div
            style={{ margin: `30px 0 0`, width: `100%` }} >
            <Input
              label                 = 'First name'
              stateKey              = { 'nameFirst' }
              inputDisabledStateKey = { inputDisabledStateK }
            />
          </div>

          <div
            style={{ margin: `20px 0 0`, width: `100%` }} >
            <Input
              label                 = 'Last name'
              stateKey              = { 'nameLast' }
              inputDisabledStateKey = { inputDisabledStateK }
            />
          </div>

          <div
            style={{ margin: `20px 0 0`, width: `100%` }} >
            <Input
              type                  = 'email'
              label                 = 'Email address'
              stateKey              = { 'email' }
              inputDisabledStateKey = { inputDisabledStateK }
            />
          </div>

          <GlobalMessage />

          <ActionButtons
            submit={ submit }
            cancel={ cancel }
            shouldDisplay={ fieldsChanged }
            disabled={ inputDisabled }
          />
          
        </div>
    );
};

import { selector } from 'recoil';
import { formState, inputDisabledState } from '../../../utils/state';

export const globalMessagePaymentK = 'paymentGlobal';

export const inputDisabledStateLocal = selector({
    key: 'paymentInputDisabledState',
    get: ({get}) => {
        const global = get(inputDisabledState);
        const local = get(formState('paymentInFlight'));
        return local || global;
    },
    set: ({set}, newValue) => {
        set(formState('paymentInFlight'), newValue);
    }
});


var monthStr = [ "Jan", "Feb", "Mar", "Apr",
                 "May", "Jun", "Jul", "Aug",
                 "Sep", "Oct", "Nov", "Dec"];

const elevenHours = 1000 * 60 * 60 * 11;

export const formatDate = (epochTs) => {
    const d = epochTs ? new Date(Number(epochTs)) : new Date();
    return monthStr[d.getUTCMonth()] + " " + d.getUTCDate() + " " + d.getUTCFullYear();
};

export const formatDateInUTC_11 = (epochTs) => {
    const d = epochTs ? new Date(Number(epochTs)) : new Date();
    const e = new Date(d.getTime() - elevenHours);
    return monthStr[e.getUTCMonth()] + " " + e.getUTCDate() + " " + e.getUTCFullYear();
};

import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { assoc,
         assocIn,
         deepMerge,
         empty,
         getIn,
         reduceKv,
         removeKv,
         threadLast } from '../../utils/functional';
import { keyDownWrapper } from '../../utils/interaction';
import { hasError, remote } from '../../utils/http/request';
import {
    fieldsState,
    formState,
    messageState,
    messagesState,
    remoteDatomState } from '../../utils/state';
import { labelNameTag, Input } from '../join/Input';
import { ActionButtons } from './shared';
import { messageByFieldError, messageStateInitial, Message } from '../message';
import { passwordIsValid } from '../../utils/validate';
import { colors } from '../colors';


//
// configuration

const fieldKs = ['passwordConfirm', 'passwordCurrent', 'passwordNew'];
const messageGlobalK = 'passwordGlobal';
const messageKs = fieldKs.concat([messageGlobalK]);
const inputDisabledStateK = 'inFlightPassword';


//
// validation helpers

const errorMessageByField = {
    passwordConfirm: () => ({ passwordConfirm: { error: messageByFieldError.password.match }}),
    passwordCurrent: () => ({ passwordCurrent: { error: messageByFieldError.password.incorrect }}),
    passwordNew:     () => ({ passwordNew: { error: getIn( messageByFieldError, ['password', 'invalid']) }})
};

const validateByField = {
    passwordConfirm: ({ fields }) => fields.passwordNew === fields.passwordConfirm,
    passwordCurrent: ({ fields }) => ! empty(fields.passwordCurrent),
    passwordNew:     ({ fields }) => passwordIsValid(fields.passwordNew)
};

const errorsByField = ({ fields }) => {
    return threadLast(
        fields,
        [reduceKv, (a,k,v) => assoc(a, k, validateByField[k]({ fields })), {}],
        [removeKv, (k,v) => v === true] );
};


//
// validate then send

const validateThenSend = async ({ fields, userData }) => {
    const r = errorsByField({ fields });
    if ( ! empty(r) )
        return { message: reduceKv(
            (a,k,v) => deepMerge(a, errorMessageByField[k]()),
            {}, r) };

    const std = await remote.sessionToken({
        query: {
            email: userData.email,
            password: fields.passwordCurrent },
        projection: ['key'] // do not want new cookies to be returned
    });
    if ( hasError(std) )
        return assocIn({}, ['message', messageGlobalK, 'error'],
                       getIn(messageByFieldError, ['global', std.error.type]));

    if ( ! Array.isArray(std) || empty(std) )
        return assocIn({}, ['message'], errorMessageByField.passwordCurrent());
    
    const umd = await remote.userMutation({ query: { password: fields.passwordConfirm } });
    if ( hasError(umd) )
        return assocIn({}, ['message', messageGlobalK, 'error'],
                       getIn(messageByFieldError, ['global', umd.error.type]));
    return null;  // success
};




//
// content

const MessageGlobal = () => {
    return (
        <Message
          stateKey={ messageGlobalK }
          name='MessagePasswordGlobal'
          style={{
              margin: `5px 0 0`,
              width: `100%`,
              fontSize: `13px`,
              fontStyle: `italic`,
              letterSpacing: `0.13px`,
          }}
        />
    );
};


const CollapsedContent = () => {
    const [expanded, setExpanded] = useRecoilState(formState('passwordExpanded'));
    const setGlobalMessage        = useSetRecoilState(messageState(messageGlobalK));
    
    const toggle = () => {
        if ( ! expanded ) setGlobalMessage({});
        setExpanded( ! expanded );
    };

    return (
        <div
          style={{
              margin: `50px 0 35px`,
              width: `100%`,
              padding: `0`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `flex-start`
          }} >

          { labelNameTag('Password') }

          <div
            style= {{
                margin: `7px 0 0`,
                fontSize: `15px`,
                lineHeight: `2.07`,
                letterSpacing: `0.15px`,
                color: colors.green
            }} >
            **********
          </div>

          <div
            name={ `ChangePasswordExpand` }
            aria-label={ `ChangePasswordExpand` }
            onClick={ toggle }
            onKeyDown={ keyDownWrapper(toggle) }
            role={ `button` }
            tabIndex={ 0 }
            style= {{
                margin: `7px 0 0`,
                fontFamily: `"Overpass", Serif`,
                fontSize: `13px`,
                lineHeight: `1.08`,
                letterSpacing: `0.33px`,
                color: colors.green,
                textDecoration: `underline`,
                cursor: `pointer`
            }} >
            Change Password
          </div>

          <MessageGlobal />
          
        </div>
    );
};



const successMessageData = { info: 'Your password has been updated' };

const ExpandedContent = () => {
    const setExpanded                   = useSetRecoilState(formState('passwordExpanded'));
    const [fields, setFields]           = useRecoilState(fieldsState(fieldKs));
    const [inputDisabled, setInFlight]  = useRecoilState(formState(inputDisabledStateK));
    const userData                      = useRecoilValue(remoteDatomState('user'));
    const setGlobalMessage              = useSetRecoilState(messageState(messageGlobalK));
    const setMessages                   = useSetRecoilState(messagesState(messageKs));

    const clearMessages = () => setMessages( messageKs.map((k) => messageStateInitial[k]) );
    
    // actions
    const cancel = () => {
        setExpanded(false);
        clearMessages();
        setFields(fieldKs.map((_) => ''));
        return true;
    };

    const submit = () => {
        setInFlight(true);
        clearMessages();
        validateThenSend({ fields, userData })
            .then((messages) => {
                if ( ! empty(messages) )
                    setMessages(messageKs.map((k) => messages.message[k] || messageStateInitial[k]));
                else {  // success
                    cancel();
                    setGlobalMessage(successMessageData);
                }
            })
            .finally(() => setInFlight(false));
    };
    
    return (
        <div
          style={{
              margin: `50px 0 35px`,
              width: `100%`,
              padding: `0`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `flex-start`
          }} >

          <div
            style={{ margin: `0`, width: `100%` }} >
            <Input
              stateKey=             'passwordCurrent'
              inputDisabledStateKey={ inputDisabledStateK }
              label=                'Current password'
              type=                 'password'
            />
          </div>

          <div
            style={{ margin: `30px 0 0`, width: `100%` }} >
            <Input
              stateKey=             'passwordNew'
              inputDisabledStateKey={ inputDisabledStateK }
              label=                'New password'
              type=                 'password'
            />
          </div>

          <div
            style={{ margin: `20px 0 0`, width: `100%` }} >
            <Input
              stateKey=             'passwordConfirm'
              inputDisabledStateKey={ inputDisabledStateK }
              label=                'Confirm new password'
              type=                 'password'
            />
          </div>

          <MessageGlobal />

          <ActionButtons
            submit={ submit }
            cancel={ cancel }
            disabled={ inputDisabled }
            text={{ cancel: 'Cancel', submit: 'Save Password'}}
          />
          
        </div>
    );
};


//
// Component

export const ChangePassword = () => {
    const expanded                  = useRecoilValue(formState('passwordExpanded'));
    const [pnMessage, setPnMessage] = useRecoilState(messageState('passwordNew'));

    useEffect(() => {
        if (empty(pnMessage))
            setPnMessage(messageStateInitial['passwordNew']);
    }, [pnMessage, setPnMessage]);
    
    if (expanded) return ( <ExpandedContent /> );
    return ( <CollapsedContent /> );
    
};

import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { formatDateInUTC_11 } from '../../utils/date';
import { assoc, empty, merge } from '../../utils/functional';
import { keyDownWrapper } from '../../utils/interaction';
import { centsToDollars, subscriptionPriceCents } from '../../utils/price';
import { remote } from '../../utils/http/request';
import { formState, messageState, remoteDatomState } from '../../utils/state';
import { subscriptionPeriod } from '../../utils/subscription';
import { colors } from '../colors';
import { messageByFieldError } from '../message';
import { ActionButtons } from './shared';
import { expiresTimestamp, defaultPaymentMethod,
         membershipStatus, planPriceCents,
         renewalCoupon, renewExpandedState,
         shouldRenew,
         subscriptionStatus,
         subscriptionTypeDerived } from './state';
import { globalMessagePaymentK } from './payment/state';
import { UpgradeCollapsed, UpgradeExpanded } from './upgrade';





//
// state

export const inputDisabledState = selector({
    key: 'renewInputDisabledState',
    get: ({get}) => {
        return get(formState('renewInFlight')) || false;
    },
    set: ({set}, newValue) => {
        set(formState('renewInFlight'), newValue);
    }
});



//
// renew / cancel


// actions

export const request = async ({ shouldRenew, subscriptionType }) => {
    const query = { should_renew: shouldRenew,
                    membership_level: subscriptionType };
    const ud = await remote.membershipMutation({ query });
    if ( ! ud || ud.error ) return ud;

    // login again, since membership period validity has changed
    await remote.sessionToken({});
    return ud;
};


// components

const subscriptionTypeWord = ({ subscriptionType }) => subscriptionType === "ALL" ? "Membership" : "Subscription";

const TitleMessage = ({ status, subscriptionType }) => {
    const t = subscriptionTypeWord({ subscriptionType });
    return (
        <p
            key={ Math.random() }
            style={{
                margin: 0,
                fontFamily: `"Overpass", sans-serif`,
                fontSize: `18px`,
                fontWeight: `bold`,
                color: colors.black
            }} >
            { status === 'renewing' ?
              `Are you sure you want to cancel your ` + t + `?` :
              `Renew your ` + t }
          </p>
    );
};


const messageTextHelpWithCancel = ({ subscriptionType }) => {
    if ( subscriptionType === "ALL" )
        return (
            <div>
                If you’d like to downgrade to a 3 Shifts Email Subscriber plan instead, we’re happy to help if you reach out to 
                <OutboundLink
                    style={{
                        margin: '0 5px',
                        color: colors.green
                    }}
                    href='mailto:help@6pages.com'>
                    help@6pages.com
                </OutboundLink>
            </div>
        );
    
    return (
        <div>
            If you have questions about your Subscription, we’re happy to help if you reach out to 
            <OutboundLink
                style={{
                    margin: '0 5px',
                    color: colors.green
                }}
                href='mailto:help@6pages.com'>
                help@6pages.com
            </OutboundLink>
        </div>
    );
};

const messageText = ({ expiresTs, priceCents, renewCoupon, status, subscriptionTypeDerived }) => {

    const dateStr = formatDateInUTC_11(expiresTs);
    const pc = status === 'expired' ? priceCents : subscriptionPriceCents({ couponData: renewCoupon, basePriceCents: priceCents }) ;
    const priceStr = String(centsToDollars(pc));
    
    if ( status === 'renewing' ) {
        const helpWithCancelText = messageTextHelpWithCancel({ subscriptionType: subscriptionTypeDerived });

        if ( subscriptionTypeDerived === "ALL" )
            return [
                'Are you sure you want to cancel your Membership?',
                helpWithCancelText,
                'If you cancel, you will no longer receive the full 6Pages 3 Shifts Edition every week and lose unlimited access to Member-only briefs and the 6Pages Repository of market shifts. You also may not be able to renew at your current rate.',
                'Your Membership will expire effective ' + dateStr + '. If you decide to renew your Membership after your expiration date, it will renew at the subscription rate available on that future date.'
            ];
        
        else // SHIFT_EMAIL_ONLY
            return [
                helpWithCancelText,
                'If you cancel, you will no longer receive the full 6Pages 3 Shifts Edition every week. You also may not be able to renew at your current rate.',
                'Your Subscription will expire effective ' + dateStr + '. If you decide to renew after your expiration date, it will renew at the subscription rate available on that future date.'
            ];
    }
    
    if ( status === 'expiring' )
        return ['Your card will be charged $' + priceStr + ' on your renewal date ' + dateStr];

    // 'expired'
    const spStr = subscriptionPeriod[subscriptionTypeDerived];
    return ['Your card will be charged $' + priceStr + ' now and each subsequent ' + spStr + ' on a recurring basis unless and until you cancel.'];
};

const InfoMessage = ({ content }) => {
    return (
        <div
            key={ Math.random() }
            style={{ margin: `20px 0 0` }} >
          { content }
        </div>
    );
};


const RenewExpanded = ({ globalMessageK }) => {

    const [expanded, setExpanded]           = useRecoilState(renewExpandedState);
    const [inFlight, setInFlight]           = useRecoilState(inputDisabledState);
    const [userData, setUserData]           = useRecoilState(remoteDatomState('user'));
    const expiresTs                         = useRecoilValue(expiresTimestamp);
    const status                            = useRecoilValue(membershipStatus);
    const priceCents                        = useRecoilValue(planPriceCents);
    const sr                                = useRecoilValue(shouldRenew);
    const renewCoupon                       = useRecoilValue(renewalCoupon);
    const [globalMessage, setGlobalMessage] = useRecoilState(messageState(globalMessageK));
    const setPaymentGlobalMessage           = useSetRecoilState(messageState(globalMessagePaymentK));
    const subscriptionType                  = useRecoilValue(subscriptionTypeDerived);

    // do not show until user data has been loaded
    if ( empty(userData) ) return ( <div/> );

    
    const toggleRenew = () => {
        if ( inFlight ) return;
        setInFlight(true);
        request({ shouldRenew: ! sr, subscriptionType })
            .then((d) => {
                if ( ! d )
                    setGlobalMessage( assoc(globalMessage, 'error', messageByFieldError.global.server) );
                else if ( d.error && d.error.type ) 
                    setGlobalMessage( assoc(globalMessage, 'error', messageByFieldError.global[d.error.type]) );
                else {
                    setExpanded(null);
                    setPaymentGlobalMessage({});
                    setUserData( merge( userData, d ) );
                }
                return;
            })
            .finally(() => setInFlight(false));
    };

    const cancel = () => {
        if ( ! inFlight ) setExpanded(null);
    };


    return (
        <div
            style={{
                margin: `40px 0 0`,
                padding: `0`,
                width: `100%`,
                display: expanded === 'renew' ? `flex` : `none`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `flex-start`
            }} >

            <TitleMessage
                status={ status }
                subscriptionType={ subscriptionType }
            />
            
            { messageText({ expiresTs, priceCents, renewCoupon, status, subscriptionTypeDerived: subscriptionType })
              .map( content => <InfoMessage content={ content } /> ) }
            
            <div style={{ margin: `25px 0 0` }} >

                <ActionButtons
                    submit={ toggleRenew }
                    cancel={ cancel }
                    disabled={ inFlight }
                    text={{
                        submit: status === 'renewing' ? 'Cancel ' + subscriptionTypeWord({ subscriptionType }) : 'Confirm Renewal',
                        cancel: status === 'renewing' ? 'Don\'t Cancel'     : 'Don\'t Renew' }}
                />
            </div>
        </div>
    );
};

const RenewCollapsed = () => {

    const [expanded, setExpanded] = useRecoilState(renewExpandedState);
    const ms                      = useRecoilValue(membershipStatus);
    const pm                      = useRecoilValue(defaultPaymentMethod);
    const ss                      = useRecoilValue(subscriptionStatus);
    const userData                = useRecoilValue(remoteDatomState('user'));

    // do not show until user data has been loaded
    if ( empty(userData) ) return ( <div/> );


    // cannot renew (in a single request) unless you have a Stripe default payment method set
    if ( empty(pm) ) return ( <div/> );

    // cannot renew (in a single request) unless you have an active Stripe subscription
    if ( ss !== null && ss !== 'active' ) return ( <div/> );
    
    
    const expandFn = () => expanded === 'renew' ? setExpanded(null) : setExpanded('renew');
    
    return (
        <div
            name={ `RenewPlanExpand` }
            aria-label={ `RenewPlanExpand` }
            onClick={ expandFn }
            onKeyDown={ keyDownWrapper(expandFn) }
            role={ `button` }
            tabIndex={ 0 }
            style= {{
                fontFamily: `"Overpass", Serif`,
                fontSize: `13px`,
                letterSpacing: `0.33px`,
                color: expanded === 'renew' ? colors.mediumDarkGrey : colors.green,
                textDecoration: `underline`,
                cursor: `pointer`
            }} >
            { ms === 'renewing' ? 'Cancel Plan' : 'Renew' }
        </div>
    );
};



//
// Component (main)

export const AccountRenew = ({ globalMessageK, userFields }) => {

    const pm       = useRecoilValue( defaultPaymentMethod );
    const ss       = useRecoilValue( subscriptionStatus );
    const userData = useRecoilValue( remoteDatomState('user') );

    const renewNotVisible = empty(userData) || empty(pm) || (ss !== null && ss !== 'active');
    
    return (
        <div
            style={{
                margin: `20px 0 0`,
                padding: `0`,
                width: `100%`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `flex-start`
            }} >

            <div
                style={{
                    margin: `20px 0 0`,
                    padding: `0`,
                    width: `100%`,
                    display: `flex`,
                    flexDirection: `row`,
                    justifyContent: `flex-start`,
                    alignItems: `flex-start`
                }} >

                <RenewCollapsed />
                <div style={ renewNotVisible ? {} : { width: `20px` } } />
                <UpgradeCollapsed />
                
            </div>

            { RenewExpanded({ globalMessageK }) }
            { UpgradeExpanded({ globalMessageK, userFields }) }
            
        </div>
    );
};

import { selector } from 'recoil';
import { contains, copy, empty,
         filter, firstVal,
         getIn, sort, threadLast } from '../../utils/functional';
import { subscriptionPriceCents } from '../../utils/price';
import { formState, remoteDatomState } from '../../utils/state';


export const billingDetailsData = selector({
    key: 'billingDetails',
    get: ({get}) => {
        const data = get(remoteDatomState('user'));
        if ( ! data ) return null;
        return {
            name: data.first_name + ' ' + data.last_name,
            email: data.email
        };
    }
});

export const customer = selector({
    key: 'customer',
    get: ({get}) => {
        const data = get(remoteDatomState('user'));
        var cs = getIn( data, ['stripe', 'customers']);
        if ( ! cs ) return null;
        return cs.sort((a,b) => b.created - a.created)[0];
    }
});

export const defaultPaymentMethod = selector({
    key: 'defaultPaymentMethod',
    get: ({get}) => {
        const c = get(customer);
        return getIn(c, ['invoice_settings', 'default_payment_method']);
    }
});

export const cardBrand = selector({
    key: 'cardBrand',
    get: ({get}) => {
        const c = get(defaultPaymentMethod);
        return getIn(c, ['card', 'brand']);
    }
});

export const cardLast4 = selector({
    key: 'cardLast4',
    get: ({get}) => {
        const c = get(defaultPaymentMethod);
        return getIn(c, ['card', 'last_4']);
    }
});

export const getIncompleteMembershipPeriod = (data) => {
    if ( ! data || ! data.membership_periods ) return null;
    var mps = data.membership_periods.map((mp) => copy(mp));
    return threadLast( mps,
                       // remove incomplete membership periods
                       [filter, (mp) => mp.end === null || mp.end === undefined],
                       [sort, (a,b) => b.created - a.created], // descending
                       firstVal);
};

export const getMostRecentMembershipPeriod = (data) => {
    if ( ! data || ! data.membership_periods ) return null;
    var mps = data.membership_periods.map((mp) => copy(mp));
    return threadLast( mps,
                       // remove incomplete membership periods
                       [filter, (mp) => mp.end !== null && mp.end !== undefined],
                       [sort, (a,b) => b.end - a.end], // descending
                       firstVal);
};

export const renewalCoupon = selector({
    key: 'renewalCoupon',
    get: ({get}) => {
        const data = get(remoteDatomState('user'));

        // forever coupon
        const mrmp = getMostRecentMembershipPeriod(data);
        const mrmpcd = getIn( mrmp, ['stripe', 'coupon', 0]);
        if ( mrmpcd && mrmpcd['duration_type'] !== 'ONCE' )
            return mrmpcd;
        
        // incomplete membership period
        const incompleteMembershipPeriod = getIncompleteMembershipPeriod(data);
        return getIn( incompleteMembershipPeriod, ['stripe', 'coupon', 0]);
    }
});

export const membershipPeriod = selector({
    key: 'membershipPeriod',
    get: ({get}) => {
        const data = get(remoteDatomState('user'));
        return getMostRecentMembershipPeriod(data);
    }
});



//
// Stripe subscription

export const getSubscriptionStatus = (membershipPeriod) => {
    if ( !membershipPeriod ) return null;
    return getIn(membershipPeriod, ['stripe', 'subscription', 'status']);
};

const deadSubscriptionStatuses = ['canceled', 'unpaid', 'incomplete_expired'];
export const subscriptionStatusIsDead = (ss) => {
    if ( ! ss || ss === null || ss === undefined )
        return true; // subscription does not exist = dead
    return contains( deadSubscriptionStatuses, ss );
};


export const subscriptionStatus = selector({
    key: 'subscriptionStatus',
    get: ({get}) => {
        const data = get(remoteDatomState('user'));

        const imp = getIncompleteMembershipPeriod(data);
        if ( imp ) {
            const ss = getSubscriptionStatus(imp);
            if (ss) return ss;
        }

        const mrmp = getMostRecentMembershipPeriod(data);
        return getSubscriptionStatus(mrmp);
    }
});


//
// status


const gracePeriodMillisDefault = 1000 * 60 * 60; // one hour

export const inGracePeriod = selector({
    key: 'inGracePeriod',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        const ss = get(subscriptionStatus);
        if ( ! mp || empty(mp) ) return null;

        // if subscription is dead (not active, not past_due) then not
        // in grace period
        if ( subscriptionStatusIsDead(ss) )
            return false;
        
        // cannot be in grace period if trying to renew
        if ( ! mp.should_renew ) return false;

        const ms = mp.membership_status;
        if ( ! ms )  return false;
        // must be expired to be in grace period
        if ( ms !== 'expired' ) return false;
        
        // if (should_renew && expired) then (probably) failed payment
        // return true (up to grace period)
        const graceMillis = Number( mp.grace || gracePeriodMillisDefault );
        const tsSinceExpired = Date.now() - Number(mp.end);

        return tsSinceExpired < graceMillis;
    }
});

export const membershipStatus = selector({
    key: 'membershipStatus',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        if ( !mp ) return mp;
        return mp.membership_status;
    }
});

export const paymentStatus = selector({
    key: 'paymentStatus',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        if ( !mp ) return null;
        return mp.payment_status;
    }
});

export const paymentStatusOriginal = selector({
    key: 'paymentStatusOriginal',
    get: ({get}) => {
        const data = get(remoteDatomState('user'));
        if ( ! data || ! data.membership_periods ) return null;
        var mps = data.membership_periods.map((mp) => copy(mp));
        const mp = mps
              .filter((a) => a.end)  // remove incomplete membership periods
              .sort((a,b) => a.end - b.end )[0];
        if ( !mp ) return null;
        return mp.payment_status;
    }
});

export const subscriptionTypeDerived = selector({
    key: 'subscriptionTypeDerived',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        if ( !mp ) return mp;
        return mp.membership_level;
    }
});


//
// prices

const priceChangeTs = 1605978000000;  // 11/21/2020 09:00 PST

export const planPriceCents = selector({
  key: 'planPriceCents',
  get: ({get}) => {
    const mp = get(membershipPeriod);

    if ( empty(mp) ) return subscriptionPriceCents({});  // complete guess

    const isActive = get(membershipStatus) !== 'expired';
    const igp = get(inGracePeriod);
    const subscriptionType = get(subscriptionTypeDerived);

    if ( ! ( isActive || igp ) )
      return subscriptionPriceCents({ subscriptionType });

    const pp = getIn(mp, ['stripe', 'subscription', 'items', 'data', firstVal, 'plan', 'amount_decimal' ]);
    if (pp) return pp;

    // special case for "founding members" who have a special (lower) price of $199/year
    if ( mp
         && subscriptionType === "ALL"
         && Number(mp.created) <= priceChangeTs )
      return 19900;

    return subscriptionPriceCents({ subscriptionType });
  }
});

export const planPriceLastCents = selector({
    key: 'planPriceLastCents',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        const pp = getIn(mp, ['stripe', 'subscription', 'latest_invoice', 'total']);
        return pp || get(planPriceCents);
    }
});



//
// membership properties

export const planInterval = selector({
    key: 'planInterval',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        const i = getIn(mp, ['stripe', 'subscription', 'plan', 'interval']);
        return i || 'year';
    }
});

export const couponMetadata = selector({
    key: 'couponMetadata',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        const md = getIn(mp, ['stripe', 'coupon', 'metadata']);
        return md || {};
    }
});

export const expiresTimestamp = selector({
    key: 'expiresTimestamp',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        return mp ? mp.end : null;
    }
});

export const setupIntentSecret = selector({
    key: 'setupIntentSecret',
    get: ({get}) => {
        const c = get(customer);
        return getIn(c, ['setup_intent', 'client_secret']);
    }
});

export const shouldRenew = selector({
    key: 'shouldRenew',
    get: ({get}) => {
        const mp = get(membershipPeriod);
        return mp ? mp.should_renew : false;
    }
});






//
// view state


export const renewExpandedState = selector({
    key: 'renewExpandedState',
    get: ({get}) => {
        return get(formState('renewExpandedState')) || null;
    },
    set: ({set}, newValue) => {
        set(formState('renewExpandedState'), newValue);
    }
});

export const upgradeSelectedState = selector({
    key: 'upgradeSelectedState',
    get: ({get}) => {
        return get(formState('upgradeSelectedState')) || null;
    },
    set: ({set}, newValue) => {
        set(formState('upgradeSelectedState'), newValue);
    }
});

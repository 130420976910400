import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { assoc, dissoc, empty, getIn, merge } from '../../utils/functional';
import { keyDownWrapper } from '../../utils/interaction';
import { centsToDollars, subscriptionPriceCents } from '../../utils/price';
import { fieldState,
         formState,
         inputDisabledState,
         messageState, remoteDatomState } from '../../utils/state';
import { subscriptionPeriod } from '../../utils/subscription';
import { refresh } from '../../utils/redirect';

import * as buttonStyles from '../button.module.css';
import { colors } from '../colors';
import { messageByFieldError, Message } from '../message';
import { options, Option } from '../public_home/membership';
import * as membershipStyles from '../public_home/membership.module.css';
import { PromoCodeInput, updatePromoCodeData } from '../join/PromoCode';
import { StripeProviderComponent } from '../stripe/provider';

import { loadAccountData } from './load';
import { createSubscription, promoCodeOnly, userMutation } from './payment/actions';
import { CardDetails } from './payment/collapsed';
import { delayPromise,
         promoCodeIsComplimentary,
         PaymentCardField } from './payment/expanded';

import { ActionButtons } from './shared';
import { billingDetailsData,
         defaultPaymentMethod,
         membershipStatus,
         renewExpandedState,
         setupIntentSecret,
         subscriptionStatus,
         subscriptionTypeDerived,
         upgradeSelectedState } from './state';
import * as stylesShared from './shared.module.css';
import * as styles from './upgrade.module.css';



//
// config

const globalMessageStateKey = 'upgradeGlobalMessage';

const promoCodeId = 'MEMUPGRADE';
const promoCodeStateKey = 'upgrade-promo-code';

const inputDisabledKey = 'upgradeRequestInFlight';

export const inputDisabledStateLocal = selector({
    key: inputDisabledKey,
    get: ({get}) => {
        const global = get(inputDisabledState);
        const local = get(formState(inputDisabledKey));
        return local || global;
    },
    set: ({set}, newValue) => {
        set(formState(inputDisabledKey), newValue);
    }
});





//
// Collapsed

export const UpgradeCollapsed = () => {
    
    const [expanded, setExpanded] = useRecoilState( renewExpandedState );
    const ms                      = useRecoilValue( membershipStatus );
    const subscriptionType        = useRecoilValue( subscriptionTypeDerived );
    const userData                = useRecoilValue( remoteDatomState('user') );

    // do not show until user data has been loaded
    if ( empty(userData) ) return ( <div/> );

    // downgrade is not supported
    if ( ms !== 'expired' && subscriptionType === 'ALL' ) return ( <div /> );
    
    const expandFn = () => expanded === 'upgrade' ? setExpanded(null) : setExpanded('upgrade');
    return (
        <div
            name={ `UpgradePlanExpand` }
            aria-label={ `UpgradePlanExpand` }
            onClick={ expandFn }
            onKeyDown={ keyDownWrapper(expandFn) }
            role={ `button` }
            tabIndex={ 0 }
            style= {{
                margin: `0`,
                fontFamily: `"Overpass", Serif`,
                fontSize: `13px`,
                letterSpacing: `0.33px`,
                color: expanded === 'upgrade' ? colors.mediumDarkGrey : colors.green,
                textDecoration: `underline`,
                cursor: `pointer`
            }} >
            Change Plan
        </div>
    );
};

const UpgradeButton = ({ priceDollars, submitFn,
                         selectedSubscriptionType, setSelectedState,
                         subscriptionPeriod, subscriptionType }) => {

  // selectedSubscriptionType, setSelectedState need to be passed in
  // to avoid React Hook rendering exceptions since UpgradeButton is
  // render multiple times
                            

    // set default for submitFn
    if ( submitFn === null || submitFn === undefined || typeof submitFn !== 'function' )
        submitFn = (event) => {
            event.preventDefault();
            const newValue = selectedSubscriptionType === subscriptionType ? null : subscriptionType;
            setSelectedState(newValue);
            return;
        };

    var content;
    if ( selectedSubscriptionType === null || selectedSubscriptionType !== subscriptionType )
        content = "Select"
    else
        content = "Selected";
    
    return (
        <div
            style={{
                minWidth: `260px`,
                margin: `20px 0 0`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `center`
            }} >

            <div
                style={{
                    display: `flex`,
                    flexDirection: `row`,
                    justifyContent: `center`,
                    alignItems: `flex-end`
                }} >
                
                <div
                    style={{
                        fontSize: `16px`,
                        color: colors.green
                    }} >
                    ${ priceDollars }
                </div>
                <div
                    style={{
                        margin: `0 0 2px 6px`,
                        fontSize: `14px`,
                        color: colors.green
                    }} >
                    per { subscriptionPeriod }
                </div>
            </div>
            
            <div
                name={ `UpgradeButton` }
                aria-label={ `UpgradeButton` }
                onClick={ submitFn }
                onKeyDown={ keyDownWrapper(submitFn) }
                role={ `button` }
                tabIndex={ 0 }
                className={ `${buttonStyles.submit} ${membershipStyles.button}` }
                style={{
                    minWidth: `260px`,
                    margin: 0,
                    fontSize: `14px`,
                    fontWeight: `300`
                }} >
                { content }
            </div>
        </div>
    );
};




//
// Submit Form

const couponExpandedState = selector({
    key: 'upgradeCouponExpandedState',
    get: ({get}) => {
        return get( formState('upgradeCouponExpandedState') ) || false;
    },
    set: ({set}, newValue) => {
        set( formState('upgradeCouponExpandedState'), newValue);
    }
});

const CouponCollapsedContent = () => {
    const [expanded, setExpanded] = useRecoilState( couponExpandedState );
    const submitFn = (e) => { e.preventDefault(); setExpanded( ! expanded ); };
    return (
        <div
            name={ `PromoCodeCollapsed` }
            aria-label={ `PromoCodeCollapsed` }
            onClick={ submitFn }
            onKeyDown={ keyDownWrapper(submitFn) }
            role={ `button` }
            tabIndex={ 0 }
            style={{
                display: expanded ? `none` : `block`,
                margin: `0`,
                fontFamily: `"Overpass", Serif`,
                fontSize: `13px`,
                letterSpacing: `0.33px`,
                color: colors.green,
                textDecoration: `none`,
                cursor: `pointer`
            }} >
            Have a Promo Code?
        </div>
    );
};

const CouponExpandedContent = ({ subscriptionTypeNext }) => {

    return (
        <div
            style={{
                margin: `20px 0 0`,
                width: `100%`
            }} >
            <PromoCodeInput
                basePriceCents={ null }
                fieldName={ promoCodeStateKey }
                inputDisabledStateKey={ inputDisabledKey }
                label='Promo code'
                name={ promoCodeStateKey }
                subscriptionTypeNext={ subscriptionTypeNext }
            />
        </div>
    );
};


const CouponContent = ({ subscriptionTypeNext }) => {
    const expanded = useRecoilValue( couponExpandedState );
    if (expanded)
        return ( <CouponExpandedContent subscriptionTypeNext={ subscriptionTypeNext } /> );
    return ( <CouponCollapsedContent /> );
};





const newCardRequiredState = selector({
    key: 'upgradeNewCardRequiredState',
    get: ({get}) => {
        return get( formState('upgradeNewCardRequiredState') ) || false;
    },
    set: ({set}, newValue) => {
        set( formState('upgradeNewCardRequiredState'), newValue);
    }
});

const PaymentContent = () => {

    const defaultPaymentMethodData = useRecoilValue( defaultPaymentMethod );
    const [newCardRequired, setNewCardRequired] = useRecoilState( newCardRequiredState );


    // when no default payment method, require a new card
    useEffect(() => {
        const showNewCard = empty( defaultPaymentMethodData );
        setNewCardRequired( showNewCard );
    }, [defaultPaymentMethodData, setNewCardRequired]);


    const submitToAddNewCard = (event) => {
        event.preventDefault();
        setNewCardRequired( ! newCardRequired );
    };


    return (
        <div
            style={{
                margin: `20px 0 0`,
                width: `100%`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `space-between`,
                alignItems: `flex-start`
            }} >


            <div
                style={{
                    display: empty( defaultPaymentMethodData ) ? `none` : `flex`,
                    margin: `0`,
                    width: `100%`,
                    flexDirection: `row`,
                    justifyContent: `space-between`,
                    alignItems: `flex-end`
                }} >

                <CardDetails />

                <div
                    name={ `PaymentCollapsed` }
                    aria-label={ `PaymentCollapsed` }
                    onClick={ submitToAddNewCard }
                    onKeyDown={ keyDownWrapper(submitToAddNewCard) }
                    role={ `button` }
                    tabIndex={ 0 }
                    style={{
                        display: newCardRequired ? `none` : `block`,
                        margin: `0 0 0 5px`,
                        fontFamily: `"Overpass", Serif`,
                        fontSize: `13px`,
                        letterSpacing: `0.33px`,
                        color: colors.green,
                        textDecoration: `none`,
                        cursor: `pointer`
                    }} >
                    New Card
                </div>

            </div>

            <div
                style={{
                    display: newCardRequired ? `block` : `none`,
                    margin: `20px 0 0`,
                    width: `100%`
                }} >
                <PaymentCardField />
            </div>

        </div>

    );
};



const infoMessageWithLinkComponent = ( s ) => {
    // special message for membershipStatus === 'expired' && subscriptionType === 'SHIFTS_EMAIL_ONLY'
    // includes link to terms of use
    return (
        <div>
            <div>{ s }</div>
            <div
                style={{
                    margin: `20px 0 0`,
                    width: `100%`,
                    fontSize: `12px`,
                    lineHeight: `1.75`,
                    letterSpacing: `0.12px`,
                    color: colors.mediumDarkGrey
                }} >
                By clicking “Confirm”, you agree to our 
                <Link
                    to={`/terms`}
                    style={{
                        margin: `0 0 0 2px`,
                        color: colors.mediumDarkGrey,
                        boxShadow: `none`,
                        textDecoration: `solid 1px underline ${colors.green}`
                    }} >
                    Terms of Use
                </Link>
                . If a payment method was collected, you authorize 6Pages, Inc. to charge the amount indicated above now and a monthly Subscription fee each subsequent month on a recurring basis unless and until you cancel. You agree to receive the regular 6Pages email briefing and other periodic communications about our service.
            </div>
        </div>
    );
};

const infoMessage = ({ couponData = null, membershipStatus, subscriptionType }) => {

  if ( subscriptionType === null ) return "";

  const subscriptionTypeToPlanName = {
    'ALL':               'All-Access Member',
    'SHIFTS_EMAIL_ONLY': '3 Shifts Subscription'
  };

  const priceDollars = centsToDollars( subscriptionPriceCents({ couponData, subscriptionType }) );
  var s = "Your card will be charged today a total of $" + priceDollars + " for the " + subscriptionTypeToPlanName[subscriptionType] + " plan.";

  if ( membershipStatus !== 'expired' )
    s = s + " This includes any refund for the current monthly subscription fee.";
  if ( membershipStatus === 'expired' && subscriptionType === 'SHIFTS_EMAIL_ONLY' )
    s = infoMessageWithLinkComponent(s);

  return s;
};



const Form = () => {

    const [selectedSubscriptionType, setUpgradeSelected]  = useRecoilState( upgradeSelectedState );
    const [renewExpanded, setExpanded]                    = useRecoilState( renewExpandedState );
    const [globalMessage, setGlobalMessage]      = useRecoilState(    messageState(globalMessageStateKey) );
    const [promoCodeData, setPromoCodeData]      = useRecoilState(    remoteDatomState(promoCodeStateKey) );
    const [promoCodeField, setPromoCodeField]    = useRecoilState(    fieldState(promoCodeStateKey) );
    const [inFlight, setInFlight]                = useRecoilState(    inputDisabledStateLocal );
    const setInFlightGlobal                      = useSetRecoilState( inputDisabledState );
    const [userData, setUserData]                = useRecoilState(    remoteDatomState('user') );
    const ss                                     = useRecoilValue(    subscriptionStatus );
    const ms                                     = useRecoilValue(    membershipStatus );
    const secret                                 = useRecoilValue(    setupIntentSecret );
    const billingDetails                         = useRecoilValue(    billingDetailsData );
    const defaultPaymentMethodData               = useRecoilValue(    defaultPaymentMethod );
    const newCardRequired                        = useRecoilValue(    newCardRequiredState );
    const subscriptionType                       = useRecoilValue(    subscriptionTypeDerived );

    const stripe = useStripe();
    const elements = useElements();



    // disable Stripe CardElement at appropriate times
    useEffect(() => {
        if (elements) {
            const ce = elements.getElement(CardElement);
            if (ce) {
                const disabled = inFlight || !stripe || false;
                ce.update({ disabled });
            }
        }
    }, [inFlight, elements, stripe]);



    // automatically load default promo code (SHIFTS_EMAIL_ONLY to ALL)
    useEffect(() => {
        const f = async () => {

            var data = null;
          if ( subscriptionType === 'SHIFTS_EMAIL_ONLY'
               && selectedSubscriptionType === 'ALL'
               && ms !== 'expired'
               && ss === 'active' )
            data = await updatePromoCodeData({ promoCodeId, selectedSubscriptionType });

            setPromoCodeData( data );
            if ( data && data.valid ) setPromoCodeField( data.id );
        };
        f();
    }, [ms, setPromoCodeData, setPromoCodeField,
        selectedSubscriptionType, ss, subscriptionType]);



    // automatically set global message, after default promo code loads
    useEffect(() => {

      const promoCodeDataId = empty(promoCodeData) ? null : promoCodeData.id;
      const promoCodeDataIdLastSeen = empty(globalMessage) ? null : ! globalMessage.promoCodeDataId ? null : globalMessage.promoCodeDataId;

      if ( selectedSubscriptionType !== null
           && ( empty(globalMessage) || promoCodeDataId !== promoCodeDataIdLastSeen )
         )
      {
          const msg = infoMessage({ couponData: promoCodeData,
                                    membershipStatus: ms,
                                    subscriptionType: selectedSubscriptionType });

        // use globalMessage.promoCodeDataId as a flag to check when changes are needed

            if ( empty(globalMessage)
                 || ! globalMessage.info ) {
              setGlobalMessage({ info: msg, promoCodeDataId });
            }

            else if ( globalMessage
                      && globalMessage.info
                      && msg !== globalMessage.info ) {
              setGlobalMessage(
                merge( globalMessage, { info: msg, promoCodeDataId } )
              );
            }
        }

    }, [globalMessage, ms, promoCodeData,
        setGlobalMessage, selectedSubscriptionType]);




    // actions

    const cancel = (e, justDoIt) => {
        if (e) e.preventDefault();
        if ( inFlight && justDoIt !== true ) return false;
        if ( elements && elements.getElement(CardElement) )
            elements.getElement(CardElement).clear();
        setUpgradeSelected(null);
        setExpanded(null);
        setGlobalMessage( dissoc(globalMessage, 'info', 'error') );
        return true;
    };

    const refreshAccountPage = async () => {
        if ( typeof window === 'undefined' ) return;
        setInFlightGlobal(true);
        await delayPromise(10);
        setInFlightGlobal(false);
    };


    const submitErrorHandling = ({ data }) => {
        if ( ! data )
            setGlobalMessage( assoc(globalMessage, 'error', messageByFieldError.global.connectivity) );

        else if ( data.error ) {

            // stripe exception messages
            const sed = getIn(data, ['error', 'data']);
            const sem = Array.isArray(sed) ? getIn(sed[0], ['message']) : null;
            if ( sem ) setGlobalMessage( assoc(globalMessage, 'error', sem) );

            // other error messages
            else if ( data.error.message )
                setGlobalMessage( assoc(globalMessage, 'error', data.error.message) );
            else if ( data.error.type )
                setGlobalMessage( assoc(globalMessage, 'error', messageByFieldError.global[data.error.type]) );
            else // field level
                setGlobalMessage(
                    assoc(globalMessage, 'error',
                          getIn( messageByFieldError,
                                 [Object.keys(data.error)[0], Object.values(data.error)[0]])));

            if ( ! promoCodeIsComplimentary(promoCodeData) )
                // if new/update subscription attempted
                // update user data, in case a subscription or
                // incomplete membership period was created by
                // not finalized
                loadAccountData().then((ud) => setUserData(ud));

        } else if ( ss !== 'active' ) {
            // [2022/10/22] what is this case?
            refresh();
        } else {
            return false;
        }
        return true;
    };

    const submit = (e) => {
        e.preventDefault();
        if ( inFlight ) return false;

        setInFlight(true);
        setGlobalMessage( dissoc(globalMessage, 'error') );

        // always need to create a new subscription with different product
        // ... or when previous subscription/membeship has expired
        var actionFn = createSubscription;
        if ( promoCodeIsComplimentary(promoCodeData) )
            actionFn = promoCodeOnly;

        const args = { billingDetails,
                       fields: { 'promoCode': promoCodeField },

                       // if not adding new card, use default payment method
                       paymentMethodId: newCardRequired ? null : defaultPaymentMethodData.id,
                       promoCodeData, secret,
                       subscriptionType: selectedSubscriptionType,
                       elements, stripe };

        actionFn(args)
            .then((d) => {

                if ( ! submitErrorHandling({ data: d }) ) {

                    const shouldRenewArgs = { shouldRenew: true,
                                              subscriptionType: selectedSubscriptionType };
                    userMutation(shouldRenewArgs)
                        .then((umd) => {

                            if ( ! submitErrorHandling({ data: umd }) ) {
                                setUserData( merge( userData, umd ) );
                                cancel(null, true);
                                refreshAccountPage();
                            }
                        });
                }
            })
            .finally(() => setInFlight(false));
        return true;
    };



    return (
        <div
            style={{
                width: `100%`,
                maxWidth: `540px`,
                margin: `40px 0 0`,
                display: renewExpanded !== 'upgrade' || selectedSubscriptionType === null ? `none` : `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `flex-start`
            }} >

            <div
                className={ stylesShared.sectionTitle }
                style={{
                    margin: 0,
                    width: `100%`
                }} >
                Confirm Plan Change
            </div>


            <PaymentContent />


            <div style={{ margin: `25px 0 0` }} />
            <CouponContent subscriptionTypeNext={ selectedSubscriptionType } />


            <div style={{ margin: `30px 0 0` }} />

            <ActionButtons
                submit={ submit }
                cancel={ cancel }
                disabled={ inFlight }
                text={{ submit: 'Confirm', cancel: 'Cancel' }}
            />

            <div style={{ margin: `5px 0 0` }} />

            <Message
                stateKey={ globalMessageStateKey }
                name='MessageUpgradeGlobal'
                style={{
                    margin: `5px 0 0`,
                    width: `100%`,
                    minHeight: `25px`,
                    fontSize: `13px`,
                    fontStyle: `italic`,
                    letterSpacing: `0.13px`
                }} />

        </div>
    );
};





//
// Upgrade Section Expanded



export const UpgradeExpanded = ({ userFields }) => {
    
    const expanded  = useRecoilValue( renewExpandedState );
    const ms        = useRecoilValue( membershipStatus );
    const [selectedSubscriptionType, setSelectedState] = useRecoilState( upgradeSelectedState );

    var sts = ['ALL'];
    if ( ms === 'expired' ) sts.unshift('SHIFTS_EMAIL_ONLY');
    
    var optionDatums = [];
    for (const subscriptionType of sts) {
        const optionsData = options({ userFields });
        const button = UpgradeButton({ priceDollars: centsToDollars(subscriptionPriceCents({ subscriptionType })),
                                       selectedSubscriptionType, setSelectedState,
                                       subscriptionPeriod: subscriptionPeriod[subscriptionType],
                                       subscriptionType })
        const od = merge( optionsData[subscriptionType],
                          { bottomComponent: button,
                            className: styles.option } );  // className: styles.option
        optionDatums.push( od );
    }
        
    
    return (
        <div
            style={{
                width: `100%`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `flex-start`
            }} >
            
            <div
                className={ `${membershipStyles.options} ${styles.optionContainer}` }
                style={{
                    display: expanded === 'upgrade' ? `flex` : `none`,
                    padding: `0`,
                }} >

                <div/>
                { optionDatums.map( optionData => Option( optionData )) }
                
            </div>

            <StripeProviderComponent>
                <Form />
            </StripeProviderComponent>
            
        </div>
    );
};

import { Link } from 'gatsby';
import React from 'react';
import { centsToDollars, subscriptionPriceCents } from '../../utils/price';
import { subscriptionPeriod } from '../../utils/subscription';
import * as buttonStyles from '../button.module.css';
import { colors } from '../colors';
import NewsletterSignup from '../newsletter_signup';
import * as styles from './membership.module.css';



//
// options

const OptionListItem = (content) => {
    return (
        <li
            className={ styles.item }
            key={ Math.random() }
            style={{
                borderBottom: `1px solid ${colors.darkerGrey}`
            }} >
            { content }
        </li>
    );
};

export const Option = ({ bottomComponent, className = styles.option, listItems, title }) => {
    return (
        <div
            className={ className }
            key={ Math.random() }
            style={{ backgroundColor: colors.mediumGrey }} >

            <div className={ styles.upper } >

                <div className={ styles.title } >
                    { title }
                </div>

                <ul className={ styles.list } >
                    { listItems.map(OptionListItem) }
                </ul>
                
            </div>
            
            <div className={ styles.lower } >
                { bottomComponent }
            </div>
        </div>
    );
};


const Signup = () => {
    return (
        <div className={ styles.newsletterSignup } >

            <NewsletterSignup
                fontSize='14px'
                height='40px'
                padding='4px 0 0'
                placeholder='Enter your email'
            />
            
        </div>
    );
};

const JoinButton = ({ name, priceDollars, subscriptionPeriod, to }) => {
    return (
        <Link
            to={ to }
            name={ name }
            role={ `button` }
            tabIndex={ 0 }
            className={ `${buttonStyles.submit} ${styles.button}` }
            style={{
                margin: 0,
                fontSize: `14px`,
                fontWeight: `300`
            }} >
            Sign up for ${ priceDollars }/{ subscriptionPeriod }
        </Link>
    );
};


const optionShiftsAccess = ({ intro, style, userFields }) => {

    const numShifts = userFields.shifts_researched;

    return (
        <div style={ style } >
            <span>{ intro }</span>
            <span> 6Pages Repository of </span>
            <Link
                className={ styles.link }
                style={{ color: colors.green }}
                to={ '/search' } >
                { numShifts } market shifts
            </Link>
        </div>
    );   // shifts_researched
};

export const options = ({ userFields }) => {
    return {
        FREE:
        { bottomComponent: Signup(),
          className: [styles.option, styles.optionFree].join(" "),
          listItems: [
              "1 full Shift every week in your inbox",
              "Summary versions of Member-only briefs"],
          title: "Start for Free" },
        

        SHIFTS_EMAIL_ONLY:
        { bottomComponent: JoinButton({ name: "SHIFTS_EMAIL_ONLY",
                                        priceDollars: centsToDollars(subscriptionPriceCents({ subscriptionType: "SHIFTS_EMAIL_ONLY" })),
                                        subscriptionPeriod: subscriptionPeriod["SHIFTS_EMAIL_ONLY"],
                                        to: "/newsubscriber" }),
          className: [styles.option, styles.optionShiftsEmailOnly].join(" "),
          listItems: [
              "Full 3 Shifts Editions every week in your inbox",
              "Summary versions of Member-only briefs",
              optionShiftsAccess({ intro: "No online access to ", style: { fontStyle: "italic" }, userFields })],
          title: "3 Shifts Email Subscriber" },

        
        ALL:
        { bottomComponent: JoinButton({ name: "MEMBERSHIP",
                                        priceDollars: centsToDollars(subscriptionPriceCents({ subscriptionType: "ALL" })),
                                        subscriptionPeriod: subscriptionPeriod["ALL"],
                                        to: "/newmember" }),
          className: [styles.option, styles.optionAll].join(" "),
          listItems: [
              "Member-only briefs, including Industry Reviews & Special Editions",
              "Full 3 Shifts Editions every week in your inbox",
              optionShiftsAccess({ intro: "Unlimited access to the ", userFields }),
              "Exclusive Member-only offers and community benefits"],
          title: "All-Access Member" }
    };
};




//
// membership component

export const Membership = ({ userFields }) => {

    const os = options({ userFields })
    
    return (
        <div className={ styles.container } >

            <div className={ styles.header } >
                <div className={ styles.title } >
                    Join us
                </div>
                <div className={ styles.body } >
                    Join thousands of executives, investors, founders and thought leaders making better and faster decisions.
                </div>
            </div>

            <div className={ styles.options } >
                { Option(os.FREE) }
                { Option(os.SHIFTS_EMAIL_ONLY) }
                { Option(os.ALL) }
            </div>
            
        </div>
    );
};

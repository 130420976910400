import React from 'react';
import { colors } from '../colors';
import * as styles from './lines.module.css';

const vr = () => {
    return (
        <div
          className={ styles.vr } />
   );
};

const hr = () => {
    return (
        <hr
          className={ styles.hr } />
    );
};

const calloutLine = () => {
    return (
        <div
          style={{
              width: `67px`,
              height: `7px`,
              backgroundColor: colors.green
          }} />
    );
};

export { hr, vr, calloutLine };
